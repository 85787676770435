import React, { Suspense, lazy, useEffect, useState } from 'react';
import { lazily } from 'react-lazily';
import {
  useMatches,
  Outlet as RouterOutlet,
  useNavigate,
  Link,
  RouteObject,
  Params,
  useLocation,
} from 'react-router-dom';
import {
  AppShell,
  Navbar,
  Header,
  useMantineTheme,
  Breadcrumbs,
  NavLink,
} from '@mantine/core';
import {
  IconHome2,
  IconDeviceAudioTape,
  IconMapPin,
} from '@tabler/icons-react';
import SocketProvider from 'src/Utility/SocketProvider';
import axios from 'axios';

export type matchData = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: unknown;
};
type crumb = { crumb: (data: matchData) => string };

const { REACT_APP_SERVER_URL, REACT_APP_SERVER_PORT } = process.env;
const serverUrl = `${REACT_APP_SERVER_URL}:${REACT_APP_SERVER_PORT}`;

export default function AppCMS() {
  const theme = useMantineTheme();
  const [loggedIn, setLogin] = useState(false);
  const [jwtToken, setJwtToken] = useState('');
  const nav = useNavigate();
  const loc = useLocation();

  // load every page navigation
  useEffect(() => {
    // check if jwt token is still valid
    const jwt = localStorage.getItem('jwt');

    if (!jwt) {
      axios
        .get(`${serverUrl}/auth`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((response) => {
          console.log('auth');
          if (!response.data) {
            nav('/cms/login');
          } else {
            localStorage.setItem('jwt', response.data);
          }
        });
    }
  }, [nav]);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      layout="alt"
      header={<AppHeader />}
      navbar={<AppNavBar />}
    >
      <Suspense fallback={<></>}>
        <SocketProvider>
          <RouterOutlet />
        </SocketProvider>
      </Suspense>
    </AppShell>
  );
}

function AppHeader() {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean((match.handle as crumb)?.crumb))
    .map((match) => ({
      ...match,
      crumb: (match.handle as crumb)?.crumb(match),
    }));
  const breadcrumb = crumbs.map((item, index) => (
    <Link key={index} to={item.pathname} className="special-link">
      {item.crumb}
    </Link>
  ));

  return (
    <Header height={{ base: 50, md: 70 }} p="md">
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Breadcrumbs>
          {[
            <Link key="-1" to="/cms" className="special-link">
              Home
            </Link>,
          ].concat(breadcrumb)}
        </Breadcrumbs>
      </div>
    </Header>
  );
}

function AppNavBar() {
  const [hidden, setHidden] = useState(false);
  const nav = useNavigate();

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!hidden}
      width={{ sm: 200, lg: 300 }}
    >
      <Navbar.Section>Outlet</Navbar.Section>
      <Navbar.Section grow mt="md">
        <NavLink
          label="Home"
          styles={{ label: { fontSize: '1rem' } }}
          icon={<IconHome2 size="1rem" stroke={1.5} />}
          onClick={() => nav('/cms')}
        />
        <NavLink
          label="Location"
          styles={{ label: { fontSize: '1rem' } }}
          icon={<IconMapPin size="1rem" stroke={1.5} />}
          onClick={() => nav('location')}
        />
        <NavLink
          label="Staff"
          styles={{ label: { fontSize: '1rem' } }}
          icon={<IconDeviceAudioTape size="1rem" stroke={1.5} />}
          onClick={() => nav('staff')}
        />
      </Navbar.Section>
      <Navbar.Section>Display logged in user here</Navbar.Section>
    </Navbar>
  );
}

// export function navlink(link: string) {
//   axios.get(`${serverUrl}/auth`, { withCredentials: true }).then((response) => {
//     if (!response.data) {
//       nav('/cms/login');
//     } else nav(link);
//   });
// }

const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('../Login/Login'));
const { Staffs, Staff } = lazily(() => import('./Staffs'));
const { Outlets, Outlet } = lazily(() => import('./Locations'));

export const CMSRoutes = {
  path: '/cms',
  element: <AppCMS />,
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: 'staff',
      children: [
        {
          index: true,
          element: <Staffs />,
        },
        {
          path: ':uuid',
          element: <Staff />,
        },
      ],
    },
    {
      path: 'location',
      children: [
        {
          index: true,
          element: <Outlets />,
        },
        {
          path: ':uuid',
          element: <Outlet />,
        },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
  ],
} as RouteObject;
