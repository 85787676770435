import React, { lazy, Suspense } from 'react';
import { lazily } from 'react-lazily';
import sass from './index.module.sass';

import { createRoot } from 'react-dom/client';
import {
  // BrowserRouter,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { CMSRoutes } from 'src/CMS';

import { MantineProvider } from '@mantine/core';

const Home = lazy(() => import('./Home/Home'));
const { ClockIn, ClockInViewer } = lazily(() => import('./ClockIn'));

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

document.body.className = sass.body;

// import App from './App';
// import { BrowserRouter } from 'react-router-dom';
// const root = createRoot(document.getElementById('root') as Element);
// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <div>
        <h1>Error 404!</h1>
        <h2>This page probably don't exists...</h2>
      </div>
    ),
  },
  {
    path: '/',
    element: (
      <div className="App">
        <Home />,
      </div>
    ),
  },
  CMSRoutes,
  {
    path: 'clockin-old',
    children: [
      {
        index: true,
        element: <ClockIn />,
      },
      {
        path: 'viewer',
        element: <ClockInViewer />,
      },
    ],
  },
]);

createRoot(document.getElementById('root') as Element).render(
  <MantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{ colorScheme: 'dark' }}
  >
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  </MantineProvider>
);
