import React, { ReactNode, useContext } from 'react';
import { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';

const ServerURL = `${process.env.REACT_APP_SERVER_URL}`;
const ServerPort = `${process.env.REACT_APP_SERVER_PORT}`;

type ICSocket = {
  socket: Socket;
};

export const SocketContext = React.createContext({} as ICSocket);
export const useSocketContext = () => useContext(SocketContext);
export default function SocketProvider({ children }: { children: ReactNode }) {
  const [_socket, setSocket] = useState<Socket>();

  useEffect(() => {
    const socket = io(`${ServerURL}:${ServerPort}`, {
      transports: ['websocket'],
    });
    setSocket(socket);

    // return () => {
    //   console.log('Socket closed.');
    //   socket.close();
    // };
  }, []);

  if (!_socket) return <></>;
  if (_socket && Object.keys(_socket).length == 0 && !_socket.connected) {
    return <>Loading...</>;
  }

  const context: ICSocket = {
    socket: _socket,
  };

  return (
    <SocketContext.Provider value={context}>{children}</SocketContext.Provider>
  );
}
